import {makeAutoObservable} from "mobx";
import moment from "moment";
import dateFormat from "../utils/dateFormat";

class FormValidatorStore {
  emailRegex: any;
  isNameValid: boolean;
  isEmailValid: boolean;
  isTitleValid: boolean;
  isCommentValid: boolean;
  isPasswordValid: boolean;
  isDateValid: boolean;

  isFirstNameValid: boolean;
  isLastNameValid: boolean;
  isStreetValid: boolean;
  isZipCodeValid: boolean;
  isCityValid: boolean;
  isCountryValid: boolean;
  isPhoneValid: boolean;

  sendForms: {
    customer: boolean;
    emailChange: boolean;
    passwordChange: boolean;
    payment: boolean;
    shipping: boolean;
  };
  sendForm: boolean;
  inProcess: boolean;
  success: boolean;
  error: boolean;
  isLoading: boolean;

  isInvalid: string[] = [];
  inputLengthValidation: string[];

  constructor() {
    makeAutoObservable(this);

    // this.emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))(?<!\.)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.isNameValid = true;
    this.isEmailValid = true;
    this.isTitleValid = true;
    this.isCommentValid = true;
    this.isPasswordValid = true;
    this.isDateValid = true;

    this.isFirstNameValid = true;
    this.isLastNameValid = true;
    this.isStreetValid = true;
    this.isZipCodeValid = true;
    this.isCityValid = true;
    this.isCountryValid = true;
    this.isPhoneValid = true;

    this.sendForms = {
      customer: false,
      emailChange: false,
      passwordChange: false,
      payment: false,
      shipping: false
    }

    this.sendForm = false;
    this.inProcess = false;
    this.success = false;
    this.error = false;
    this.isLoading = false;

    this.isInvalid = [];
    this.inputLengthValidation = ['firstName', 'lastName', 'street', 'zipCode', 'city', 'companyName', 'title', 'name', 'comment']

  }

  enableAnimation() {
    this.sendForm = true;
    this.inProcess = true;
  }

  disableAnimation() {
    this.sendForm = false;
    // @ts-ignore
    Object.keys(this.sendForms).forEach((key) => this.sendForms[key] = false);
  }

  buttonResponse(result: boolean) {
    this.inProcess = false;
    if (result) {
      this.success = true;
      setTimeout(() => this.success = false, 2000);
    } else {
      this.error = true;
      setTimeout(() => this.error = false, 2000);
    }

    setTimeout(() => this.disableAnimation(), 2010);
  }

  validateResult(result: boolean, key: string, type?: string|null) {
    if (type) key = key + '-' + type;
    if (result) {
      this.isInvalid = this.isInvalid.filter((i) => i !== key);
    }
    else {
      this.isInvalid.push(key);
    }
  }

  validateInputLength(input: any, key: string, type?: string|null) {
    this.validateResult(input.length, key, type);
  }

  validateEmail(input: any, key: string, type?: string|null) {
    this.validateResult(input.length && input.match(this.emailRegex), key, type);
  }

  validateEmailChange(input: any, confirm: any, key: string, type?: string|null) {
    this.validateResult(confirm.length && confirm.match(this.emailRegex) && input === confirm, key, type);
  }

  validateDate(input: any, key: string, type?: string|null) {
    const date = dateFormat(input);
    this.validateResult(input.length && input !== 'Invalid date' && (moment(date).isAfter('1900-01-01') && moment(date).isBefore('2030-12-31')), key, type);
  }

  validatePassword(input: any, key: string, type?: string|null) {
    this.validateResult(input.trim().length, key, type);
  }

  validatePasswordChange(input: any, confirm: any, key: string, type?: string|null) {
    this.validateResult(confirm.length && input.length && input === confirm, key, type);
  }

  validatePhone(input: any, key: string, type?: string|null) {
    this.validateResult(!input.length || (input.length >= 10 && input.length <=13), key, type);
  }

  validateZipCode(input: any, key: string, type?: string|null) {
    this.validateResult(input > 1000 && input < 9999 && input.length < 5, key, type);
  }

  validateCountry(input: any, key: string, type?: string|null) {
    this.validateResult(!!input, key, type);
  }

  validateFields(data: any, validationFields: any[], keepResult: boolean = false) {
    if (!keepResult) this.isInvalid = [];
    let $this = this;
    let type = data.type;

    for (let key in data) {
      if (!validationFields.includes(key)) continue;

      if ($this.inputLengthValidation.includes(key)) {
        this.validateInputLength(data[key], key, type);
      }

      switch(key) {
        case 'email':
          this.validateEmail(data[key], key, type);
          break;

        case 'birthday':
          this.validateDate(data[key], key, type);
          break;

        case 'date':
          this.validateDate(data[key], key, type);
          break;

        case 'password':
          this.validatePassword(data[key], key, type);
          break;

        case 'phone':
          this.validatePhone(data[key], key, type);
          break;

        case 'zipCode':
          this.validateZipCode(data[key], key, type);
          break;

        case 'country':
          this.validateCountry(data[key], key, type);
          break;
      }
    }
  }
}

let formValidatorStore = new FormValidatorStore();
export default formValidatorStore;