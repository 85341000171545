import React, {useState} from "react";
import i18next from "i18next";
import formValidatorStore from "../../stores/formValidatorStore";
import apiClient from "../../utils/apiClient";
import {observer} from "mobx-react";
import {useRouter} from "next/router";
import {useDispatch} from "react-redux";
import {closeOverlay} from "../../stores/loginOverlay/loginOverlaySlice";
import {showListOverlay} from "../../stores/wishlist/wishlistSlice";
import wishlistService from "../../services/wishlistService";
import LoginForm from "../../models/LoginForm";
import tagManager from "../../services/tagManager";
import Link from "../link/Link";
import PrimaryLoadingButton from "../button/PrimaryLoadingButton";
import AlertError from "../alert/error";
import FormControlLabel from "@mui/material/FormControlLabel";
import {Checkbox, TextField} from "@mui/material";

interface Props {
    isOverlay?: boolean
}

function LoginSection({isOverlay = false}: Props) {
    const [formLoading, setFormLoading] = useState(false);
    const validationFields = ['email', 'password'];
    const [form, setForm] = useState(new LoginForm());
    const [error, setError] = useState(false);
    const router = useRouter();
    const isCheckout: any = router.query.checkout;
    const target = router.query.target;
    const dispatch = useDispatch();

    function onEmailInput(value: string) {
        form.email = value;
        setForm({...form});
        formValidatorStore.validateEmail(value, 'email')
    }

    function onPasswordInput(value: string) {
        form.password = value;
        setForm({...form});
        formValidatorStore.validatePassword(value, 'password')
    }

    function onKeepLoginChange(value: boolean) {
        form.keepLogin = value;
        setForm({...form});
    }

    async function login(e: any) {
        e.preventDefault();
        setFormLoading(true);
        formValidatorStore.validateFields(form, validationFields);
        if (formValidatorStore.isInvalid.length) {
            setFormLoading(false);
            return false;
        }

        let resLogin: any;
        try {
            await apiClient.csrf();
            await wishlistService.cookie();
            resLogin = await apiClient.post('/api/auth', {form: form});
        }
        catch(e) {
            apiClient.clearStorage();
            apiClient.clearCookies();
        }

        setFormLoading(false);
        setError(!resLogin.data);
        if (!resLogin.data) return;

        setForm(new LoginForm());
        tagManager.login()
        let path: string;
        if (parseInt(isCheckout)) {
            path = `/${i18next.language}/checkout/confirm`
        } else if (target) {
            path = `/${i18next.language}/${target}`;
        } else {
            path = `/${i18next.language}/account/overview`
        }
        if (!isOverlay) return await router.push(path);
        await loadWishlist();
    }

    async function loadWishlist() {
        dispatch(closeOverlay());
        dispatch(showListOverlay());
    }

    return (
        <div className={`col-12 mb-4 mb-md-0 ${parseInt(isCheckout) ? 'col-xl-4' : 'col-xl-6'} ${isOverlay && 'col-xl-12'}`}>
            <div className="h-100 bg-light border-light no-hover rounded-3 p-5">
                <p className="fw-bold mb-4">{i18next.t('general.isCustomer').toString()}</p>

                {error && (
                    <AlertError
                        title={i18next.t('login.failed').toString()}
                        onClick={() => setError(false)}/>
                )}

                <form onSubmit={login}>
                    <div className="mb-3">
                        <TextField id="email"
                                   label={i18next.t('general.email').toString()}
                                   variant="outlined"
                                   value={form.email || ''}
                                   onChange={(e: any) => onEmailInput(e.target.value)}
                                   onInput={(e: any) => onEmailInput(e.target.value)}
                                   required
                                   fullWidth
                                   autoComplete={"email"}
                                   autoFocus
                                   InputLabelProps={{shrink: true}}
                                   type={"email"}
                                   inputProps={{
                                       pattern: "[^@\\s]+@[^@\\s]+\\.[^@\\s]+",
                                       autocomplete: "email"
                                   }}/>
                    </div>

                    <div className="mb-3">
                        <TextField id="password"
                                   label={i18next.t('general.password').toString()}
                                   variant="outlined"
                                   value={form.password || ''}
                                   onChange={(e: any) => onPasswordInput(e.target.value)}
                                   onInput={(e: any) => onPasswordInput(e.target.value)}
                                   required
                                   fullWidth
                                   InputLabelProps={{shrink: true}}
                                   type={"password"}
                                   autoComplete={"current-password"}
                                   inputProps={{
                                       autocomplete: "current-password"
                                   }}/>
                    </div>

                    <div className="mb-4 d-flex flex-row justify-content-between">
                        <div>
                            <FormControlLabel
                                label={i18next.t('general.remember').toString()}
                                control={
                                    <Checkbox checked={form.keepLogin}
                                              onChange={((e: any) => onKeepLoginChange(e.target.checked))}/>
                                }/>
                        </div>
                        <div className={"pt-2"}>
                            <Link href={`/${i18next.language}/password`}>
                                {i18next.t('password.forgot').toString()}
                            </Link>
                        </div>
                    </div>

                    <PrimaryLoadingButton
                        label={"Login"}
                        icon={<></>}
                        loading={formLoading}
                    />
                </form>
            </div>
        </div>
    )
}

export default observer(LoginSection)