import React from "react";

type Props = React.PropsWithChildren<{
    title?: string;
    onClick: React.ComponentPropsWithoutRef<'div'>['onClick'];
    className?: string;
}>

export default function AlertError({title, children, onClick, className = ''}: Props) {
    return (
        <div className={`alert alert-danger position-relative ${className}`} role="button" onClick={onClick}>
            <i className="fa-regular fa-xmark position-absolute top-50 end-0 translate-middle text-black me-4 fs-6"></i>
            <span>{title ?? children}</span>
        </div>
    )
}
