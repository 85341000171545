import i18next from "i18next";
import {useState} from "react";
import wishlistService from "../../services/wishlistService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/pro-regular-svg-icons";
import {useRouter} from "next/router";

export default function WishlistAddForm() {
    const [error, setError] = useState(false);
    const [name, setName] = useState('');
    const router = useRouter();

    async function addList() {
        const data = await wishlistService.addList(name);
        if (!data) return setError(data);
        setName('');
        return router.push(router.asPath);
    }

    function onNameInput(name: string) {
        setName(name);
        setError(false);
    }

    return (
        <div className="row g-1" style={{minWidth: "300px", height: "38px"}}>
            <div className="col-10">
                <input type="text"
                       className={`form-control ${error && 'is-invalid'}`}
                       value={name}
                       onKeyUp={(e: any) => e.key === 'Enter' && addList()}
                       placeholder={i18next.t('list.new').toString()}
                       onInput={(e: any) => onNameInput(e.target.value)}/>
            </div>
            <div className="col-2">
                <button type="button" className="btn btn-black w-100 text-center h-100 position-relative text-white"
                        onClick={() => addList()}>
                    <FontAwesomeIcon icon={faCheck}
                                     className={"position-absolute top-50 start-50 translate-middle pt-1"}/>
                </button>
            </div>
        </div>
    )
}