import axios from "axios";
import cookieService from "./bxCookieService";
import {GetServerSidePropsContext} from "next";
import getClientIp from "../../utils/getClientIp";
import {IncomingMessage} from "http";
import sqlInjectionService from "../swisspost/sqlInjectionService";

type Request = GetServerSidePropsContext["req"];

export interface InspectionData {
    url: string;
    requestJson: string;
    responseJson: string;
    measuredTime: string;
    boxalinoTime: string;
}

const variantSensitiveFields = [
    'discountedPrice',
    'bq_std_discount_percent',
] as const;

class BxClient {
    private inspectionData = new WeakMap<IncomingMessage, InspectionData[]>();

    getBaseData(
        locale: string,
        request: Request,
        response: any,
        url: string,
        hitCount: number,
        widget: string,
        filters: any,
        bxFilters?: any,
        bxFacets?: any,
        offset?: number,
        customerId?: number,
        contextParameters?: Record<string, string>,
    ) {
        const sessionId: string = cookieService.getSessionId(request, response);
        const profileId: string = cookieService.getProfileId(request, response);

        return this.buildBaseData(
            locale,
            sessionId,
            profileId,
            widget,
            getClientIp(request),
            request.headers['user-agent'],
            request.headers.referer,
            url,
            hitCount,
            filters,
            bxFilters,
            bxFacets,
            offset,
            customerId,
            contextParameters,
        );
    }

    buildBaseData(
        locale: string,
        sessionId: string,
        profileId: string,
        widget: string,
        ip: string|undefined,
        userAgent: string|undefined,
        referer: string|undefined,
        url: string,
        hitCount: number,
        filters: any,
        bxFilters: any[] = [],
        bxFacets: any = [],
        offset: number = 0,
        customerId?: number,
        contextParameters: Record<string, string> = {}
    ) {
        const requestFilters = [
            {field: 'active', values: [1], negative: false},
            ...bxFilters.filter((i: any) => i)
        ];

        if (
            !bxFacets?.some((facet: any) => variantSensitiveFields.includes(facet.field))
            && !variantSensitiveFields.includes(filters.sort)
        ) {
            requestFilters.push({field: 'is_main_variant', values: [1], negative: false});
        }

        return {
            apiKey: process.env.BOXALINO_API_KEY ?? process.env.NEXT_PUBLIC_BOXALINO_API_KEY_JS,
            apiSecret: process.env.BOXALINO_API_SECRET ?? '',
            sessionId: sessionId,
            profileId: profileId,
            customerId: customerId ?? profileId,
            username: process.env.NEXT_PUBLIC_BOXALINO_ACCOUNT,
            language: locale,
            test: process.env.NEXT_PUBLIC_BOXALINO_TEST_MODE?.toLowerCase() === 'true',
            dev: process.env.NEXT_PUBLIC_BOXALINO_DEV_INDEX?.toLowerCase() === 'true',
            widget: this.resolveWidget(widget),
            offset: Math.abs(offset),
            returnFields: [
                'id',
                'products_group_id',
                'title',
                'sku',
                'image',
                'gallery',
                'brand',
                'main_detail_id',
                'additionaltext',
                'instock',
                'laststock',
                'vote',
                'vote_count',
                'link',
                'discountedPrice',
                'selected_list_price'
            ],
            parameters: {
                'User-Host': ip,
                'User-Agent': userAgent,
                'User-Referer': referer,
                'User-URL': url,
                test: process.env.NEXT_PUBLIC_BOXALINO_TEST_MODE,
                language: locale,
                page: 1,
                narrative_variant: "leaf_category",
                ...contextParameters
            },
            filters: requestFilters,
            groupBy: 'products_group_id',
            facets: bxFacets ? bxFacets : [],
            hitCount: hitCount,
            sort: [
                {
                    field: sqlInjectionService.parse(filters.sort) ?? 'score',
                    reverse: sqlInjectionService.parse(filters.reverse) ?? true,
                }
            ]
        };
    }

    async post(data: any, request: IncomingMessage|undefined, signal?: AbortSignal) {
        const host = request ? process.env.NEXT_PUBLIC_BOXALINO_HOST : process.env.NEXT_PUBLIC_BOXALINO_CLIENT_HOST
        try {
            if (data.test) console.log('bx request body', JSON.stringify(data));

            const url = `https://${host}/narrative/${process.env.NEXT_PUBLIC_BOXALINO_ACCOUNT}/api/1?profileId=${data.profileId}`;
            const before = performance.now();
            const response = await axios.post(url, data, { signal });
            const after = performance.now();
            this.handleInspect(request, url, data, response.data, after - before);
            return response;
        } catch (e: any) {
            const title: string = 'bxError'
            console.error(title, e);
            // void discordService.send(`${title} ${JSON.stringify(data)}} ${this.getResponseData(e)}`);
            return false;
        }
    }

    getResponseData(e: any) {
        try {
            return e.response.data;
        }
        catch(e) {
            return '';
        }
    }

    private resolveWidget(widget: string) {
        return process.env[`NEXT_PUBLIC_BOXALINO_WIDGET_OVERRIDE_${widget.toUpperCase()}`] ?? widget;
    }

    private handleInspect(
        request: IncomingMessage|undefined,
        url: string,
        requestData: any,
        responseData: { performance: { started_ts: number, completed_ts: number } },
        measuredTime: number
    ) {
        const userUrl = request?.headersDistinct['user-url']?.[0];
        if (!userUrl) {
            return;
        }
        const inspectKey = new URLSearchParams(userUrl.split('?')[1] ?? '').get('_bx_inspect_key')

        if (inspectKey !== process.env.BOXALINO_API_KEY) return;
        if (!this.inspectionData.has(request)) this.inspectionData.set(request, []);

        const requestSanitized = { ...requestData };
        delete requestSanitized.apiKey;
        delete requestSanitized.apiSecret;

        const { started_ts: boxalinoStart, completed_ts: boxalinoEnd } = responseData.performance;

        this.inspectionData.get(request)!.push({
            url,
            requestJson: JSON.stringify(requestSanitized, null, 2),
            responseJson: JSON.stringify(responseData, null, 2),
            measuredTime: `${Math.round(measuredTime)}ms`,
            boxalinoTime: `${boxalinoEnd - boxalinoStart}ms`
        });
    }

    getInspectionData(request: IncomingMessage) {
        return this.inspectionData.get(request) ?? [];
    }
}

const bxClient = new BxClient();
export default bxClient;
