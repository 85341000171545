import i18next from "i18next";
import React from "react";

export default function FooterOpening() {
    return (
        <div className="row mt-3 mt-xl-0">
            <div className="col-5 col-md-12">
                <p>{i18next.t('footer.openingDays').toString()}</p>
                <p>{i18next.t('opening.morning').toString()}</p>
                <p>{i18next.t('opening.afternoon').toString()}</p>
            </div>
            <div className="col-7 col-md-12 mt-4">
                <p className="d-flex flex-row gap-2">
                    <i className="fa-solid fa-phone"></i>
                    <a href="tel:052 544 16 00">
                        052 544 16 00
                    </a>
                </p>
                <p className="d-flex flex-row gap-2">
                    <i className="fa-brands fa-whatsapp"></i>
                    <a href="tel:078 666 68 72">
                        078 666 68 72
                    </a>
                </p>
                <p className="d-flex flex-row gap-2">
                    <i className="fa-solid fa-envelope"></i>
                    <a href="mailto:shop@perfecthair.ch">
                        shop@perfecthair.ch
                    </a>
                </p>
            </div>
        </div>
    )
}