import i18next from "i18next";
import {useDispatch, useSelector} from "react-redux";
import {State} from "../../stores/store";
import {closeWishlist, openWishlist, setLists} from "../../stores/wishlist/wishlistSlice";
import React, {useEffect, useState} from "react";
import wishlistService from "../../services/wishlistService";
import cartService from "../../services/cartService";
import {openCart} from "../../stores/cart/cartSlice";
import {styled} from '@mui/material/styles';
import MuiAccordion, {AccordionProps} from '@mui/material/Accordion';
import MuiAccordionSummary, {AccordionSummaryProps,} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Link from "next/link";

export default function WishlistOffcanvas() {
    const isOpen = useSelector((state: State) => state.wishlistState.isOpen);
    const isOverlayOpen = useSelector((state: State) => state.wishlistState.isListOpen)
    const lists = useSelector((state: State) => state.wishlistState.lists);
    const [isLoading, setIsLoading] = useState(true);
    const [name, setName] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [editId, setEditId] = useState<any>(null);
    const dispatch = useDispatch();
    const [expanded, setExpanded] = React.useState<string | false>('panel0');

    function handleChange(panel: string) {
        setExpanded(panel === expanded ? false : panel);
    }

    useEffect(() => {
        if (!isOpen) return;

        const fn = async () => {
            await wishlistService.cookie();
            const data = await wishlistService.load();
            dispatch(setLists(data));
            setIsLoading(false);
        };

        void fn();
    }, [isOpen, isOverlayOpen])

    const toggleDrawer = () =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event &&
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }
            isOpen ? dispatch(closeWishlist()) : dispatch(openWishlist());
        };

    function setEdit(id: number, name: string) {
        setName(name);
        setIsEdit(true);
        setEditId(id);
    }

    function cancelEdit() {
        setIsEdit(false);
        setEditId(null);
    }

    async function updateName(id: number) {
        const data = await wishlistService.rename(id, name);
        if (data) {
            setIsEdit(false);
            setEditId(null);
            dispatch(setLists(data));
        }
    }

    async function removeList(id: number) {
        const data = await wishlistService.removeList(id);
        dispatch(setLists(data));
    }

    async function removeItem(id: number) {
        setIsLoading(true);
        const data = await wishlistService.removeItem(id);
        dispatch(setLists(data));
        setIsLoading(false);
    }

    async function addItemToCart(orderNumber: string) {
        await cartService.addToCart(orderNumber);
        await loadCart();
    }

    async function addItemsToCart(id: number) {
        await cartService.addItemsToCart(id);
        await loadCart();
    }

    async function loadCart() {
        dispatch(closeWishlist());
        dispatch(openCart());
    }

    return (
        <Link href={`/${i18next.language}/wishlist`} className="col-3">
                <span className="d-block text-center" aria-label={"Cart"} role="button" onClick={toggleDrawer()}>
                    <i className="fa-solid fa-heart"></i>
                    <p className="sup-2 d-none d-md-block">{i18next.t('general.wishlist').toString()}</p>
                </span>
        </Link>
    )

}

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        // expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem'}}/>}
        {...props}
    />
))(({theme}) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));